<template>
  <div>
    <!-- <v-spacer /> -->
    <div style="text-align: center">
      <!-- 画像を選択するボタン -->
      <v-btn
        id="btn-upload"
        class="menu-item border my-1 mx-1"
        color="primary"
        @click="triggerFileInput"
      >
        <v-icon left>mdi-camera</v-icon>
        {{ $t('message.glamour_recipe_select_image') }}
      </v-btn>

      <!-- ダウンロードボタン -->
      <v-btn
        id="btn-download"
        class="menu-item border my-1 mx-1"
        color="success"
        @click="downloadImage"
      >
        <v-icon left>mdi-download</v-icon>
        {{ $t('message.glamour_recipe_download_image') }}
      </v-btn>

      <!-- ミラプリレシピをDiscordに投稿するボタン -->
      <v-btn
        id="btn-discord-post"
        class="menu-item border my-1 mx-1"
        color="secondary"
        @click="openConfirmationDialog"
      >
        <v-icon left>mdi-upload</v-icon>
        {{ $t('message.glamour_recipe_post_discord') }}
      </v-btn>

      <v-snackbar v-model="snackbar" :timeout="3000" color="success">
        {{ $t('message.server_data_submission') }}
      </v-snackbar>

      <!-- タグを付けてツイートするボタン -->
      <v-btn
        id="btn-twitter-post"
        class="menu-item border my-1 mx-1"
        color="info"
        @click="postToTwitter"
      >
        <v-icon left>mdi-twitter</v-icon>
        {{ $t('message.glamour_recipe_post_twitter') }}
      </v-btn>
    </div>

    <v-dialog v-model="postDialog" max-width="600px">
      <v-card>
        <v-card-title class="text-h5">{{
          $t('message.login_required_title')
        }}</v-card-title>

        <v-card-text>
          {{ $t('message.login_required_confirmation_post') }}
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            id="btn-download"
            class="menu-item border my-1 mx-1"
            color="success"
            @click="downloadImage"
          >
            <v-icon left>mdi-download</v-icon>
            {{ $t('message.glamour_recipe_download_image') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="loginDialog" max-width="600px">
      <v-card>
        <v-card-title class="text-h5">{{
          $t('message.login_required_title')
        }}</v-card-title>

        <v-card-text>
          {{ $t('message.login_required_confirmation') }}
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="confirmLogin">{{
            $t('message.login')
          }}</v-btn>
          <v-btn color="red darken-1" text @click="cancelLogin">{{
            $t('message.cancel_post')
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- 非表示のファイル入力 -->
    <input
      ref="fileInput"
      type="file"
      accept="image/*"
      style="display: none"
      @change="inputImage"
    />
    <p style="display: none">
      <canvas id="preview" />
    </p>

    <!-- 確認ダイアログ -->
    <v-dialog v-model="confirmationDialog" max-width="400px">
      <v-card>
        <v-card-title class="text-h5">
          {{ $t('message.confirm_post') }}
        </v-card-title>

        <v-card-text>
          {{ $t('message.confirm_post_discord') }}

          <!-- タイトル入力欄 -->
          <v-text-field
            v-model="postTitle"
            :label="$t('message.title_optional')"
            outlined
            clearable
          ></v-text-field>

          <!-- 備考入力欄 -->
          <v-textarea
            v-model="postRemarks"
            :label="$t('message.remarks_optional')"
            outlined
            rows="3"
            clearable
          ></v-textarea>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="confirmPost">
            {{ $t('message.confirm') }}
          </v-btn>
          <v-btn color="red darken-1" text @click="cancelPost">
            {{ $t('message.cancel') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <tui-image-editor
      id="imageEditor"
      ref="tuiImageEditor"
      scoped
      :include-ui="useDefaultUI"
      :options="options"
      @objectActivated="objectActivated"
    />
    <v-chip-group>
      <v-chip dark color="primary" @click="addCopyright()">
        {{ $t('message.glamour_recipe_maker_copyright') }}
      </v-chip>

      <!-- <v-chip dark color="green">
        <v-switch
          v-model="autoName"
          :label="$t('message.glamour_recipe_maker_auto_name')"
        />
      </v-chip> -->
      <div>
        <!-- 拡大チップ -->
        <v-chip dark color="primary" @click="scaleUp">
          <v-icon left>mdi-circle-expand</v-icon>
          拡大
        </v-chip>

        <!-- 縮小チップ -->
        <v-chip dark color="primary" @click="scaleDown">
          <v-icon left>mdi-arrow-collapse-all</v-icon>
          縮小
        </v-chip>
      </div>
    </v-chip-group>
    <v-data-iterator
      sort-by="IL"
      :sort-desc="sortDesc"
      :items="filteredItems"
      :footer-props="{ 'items-per-page-options': [50, 100, 200] }"
      :items-per-page="50"
      :page="page"
      :options="options"
    >
      <template v-slot:header>
        <v-toolbar dark color="gray darken-3" class="mb-1">
          <v-text-field
            v-model.lazy="search"
            clearable
            flat
            solo-inverted
            hide-details
            prepend-inner-icon="mdi-magnify"
            :label="$t('message.glamour_recipe_search_for_equipment')"
          />
          <template v-if="$vuetify.breakpoint.mdAndUp">
            <v-spacer />
            <v-btn-toggle v-model="sortDesc" mandatory>
              <v-btn large depressed color="gray" :value="false">
                <v-icon>mdi-arrow-up</v-icon>
              </v-btn>
              <v-btn large depressed color="gray" :value="true">
                <v-icon>mdi-arrow-down</v-icon>
              </v-btn>
            </v-btn-toggle>
          </template>
        </v-toolbar>
        <v-card>
          <v-card-title primary-title style="font-size: 1.15rem">
            <v-icon left>mdi-filter</v-icon>
            {{ $t('message.glamour_recipe_equipment_filter') }}

            <!-- スペーサーでボタンを右寄せ -->
            <v-spacer></v-spacer>

            <!-- パネルと同じデザインのボタン -->
            <v-btn class="panel-item" @click="clearFilters">
              {{ $t('message.glamour_recipe_clear_filters') }}
            </v-btn>
          </v-card-title>

          <v-card-subtitle style="font-size: 0.7rem">
            {{ $t('message.glamour_recipe_filter_description') }}
          </v-card-subtitle>

          <v-divider></v-divider>
          <div class="panel-container">
            <v-expansion-panels
              v-for="(panel, panelIndex) in panels[$i18n.locale]"
              :key="panelIndex"
              class="panel-item"
              accordion
            >
              <v-expansion-panel>
                <v-expansion-panel-header>
                  {{ panel.title }}
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-chip-group column>
                    <v-chip
                      v-for="(equipment_type, chipIndex) in panel.typeArray"
                      :key="chipIndex"
                      :color="
                        selectedEquipmentTypes.includes(equipment_type)
                          ? 'primary'
                          : ''
                      "
                      @click="toggleEquipmentTypeFilter(equipment_type)"
                    >
                      {{ equipment_type }}
                    </v-chip>
                  </v-chip-group>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>

            <v-expansion-panels class="mb-2">
              <v-expansion-panel>
                <v-expansion-panel-header>
                  {{ $t('message.glamour_recipe_jobs') }}
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-chip-group column>
                    <v-chip
                      v-for="(job, index) in localizedAvailableJobs"
                      :key="index"
                      :outlined="!isSelectedJob(job.name)"
                      :color="getJobChipBorderColor(job.name)"
                      :class="{ primary: isSelectedJob(job.name) }"
                      @click="toggleJobFilter(job.name)"
                    >
                      {{ job.name }}
                    </v-chip>
                  </v-chip-group>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </div>
        </v-card>
      </template>

      <template v-slot:default="props">
        <v-container id="equipment-view" fluid tag="section">
          <v-row>
            <v-col
              v-for="item in props.items"
              :key="item.name"
              cols="6"
              sm="6"
              md="3"
              lg="2"
              xl="1"
            >
              <v-card class="text-center" min-width="155px">
                <a :href="item.url" target="_blank">
                  <v-img :src="item.image_url" />
                </a>

                <v-card-text class="text-center">
                  <h6 class="text-h5 mb-3 text--primary">
                    {{ item.name }}
                  </h6>
                  <h4 class="text-h6 mb-2 text--secondary">
                    {{ item.type }}
                  </h4>
                  <h4 class="text-h6 mb-2 text--secondary">IL:{{ item.IL }}</h4>

                  <v-row v-if="item.color === '1'" justify="center">
                    <v-col cols="auto">
                      <!-- 染色1 ボタン -->
                      <v-btn
                        :style="{
                          backgroundColor:
                            selectedColors[item.name] &&
                            selectedColors[item.name][0],
                          color:
                            selectedColors[item.name] &&
                            selectedColors[item.name][0]
                              ? getTextColor(selectedColors[item.name][0])
                              : '#000', // デフォルトは黒色
                        }"
                        @click="openColorPicker(item.name, 0)"
                      >
                        {{
                          (selectedColorNames[item.name] &&
                            selectedColorNames[item.name][0]) ||
                          $t('message.glamour_recipe_dye_1')
                        }}
                      </v-btn>
                    </v-col>
                    <v-col cols="auto">
                      <!-- 染色2 ボタン -->
                      <v-btn
                        :style="{
                          backgroundColor:
                            selectedColors[item.name] &&
                            selectedColors[item.name][1],
                          color:
                            selectedColors[item.name] &&
                            selectedColors[item.name][1]
                              ? getTextColor(selectedColors[item.name][1])
                              : '#000', // デフォルトは黒色
                        }"
                        @click="openColorPicker(item.name, 1)"
                      >
                        {{
                          (selectedColorNames[item.name] &&
                            selectedColorNames[item.name][1]) ||
                          $t('message.glamour_recipe_dye_2')
                        }}
                      </v-btn>
                    </v-col>
                  </v-row>

                  <!-- Separate row for Add button -->
                  <!-- ref を動的に指定 -->
                  <color-picker-dialog
                    :ref="'colorPicker_' + item.name"
                    v-model="selectedColors[item.name]"
                    @colorSelected="
                      (selectedColor, index) =>
                        updateSelectedColor(item.name, selectedColor, index)
                    "
                  ></color-picker-dialog>

                  <v-row justify="center">
                    <v-col cols="auto">
                      <v-chip-group v-model="textAlignment" mandatory column>
                        <v-chip
                          small
                          tile
                          :value="'left'"
                          :class="
                            textAlignment === 'left'
                              ? 'primary-chip'
                              : 'custom-chip'
                          "
                        >
                          <v-icon small>mdi-format-align-left</v-icon>
                        </v-chip>
                        <v-chip
                          small
                          tile
                          :value="'center'"
                          :class="
                            textAlignment === 'center'
                              ? 'primary-chip'
                              : 'custom-chip'
                          "
                        >
                          <v-icon small>mdi-format-align-center</v-icon>
                        </v-chip>
                        <v-chip
                          small
                          tile
                          :value="'right'"
                          :class="
                            textAlignment === 'right'
                              ? 'primary-chip'
                              : 'custom-chip'
                          "
                        >
                          <v-icon small>mdi-format-align-right</v-icon>
                        </v-chip>
                      </v-chip-group>
                    </v-col>
                  </v-row>

                  <v-btn
                    style="padding: 0"
                    color="primary"
                    rounded
                    @click="makeIcon(item.image_url, item.name, autoName)"
                  >
                    {{ $t('message.glamour_recipe_maker_add_icon') }}
                  </v-btn>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </v-data-iterator>
  </div>
</template>

<script>
  // ライブラリのインポート
  import axios from 'axios';
  import { ImageEditor } from '@toast-ui/vue-image-editor';
  import ColorPickerDialog from '../components/ColorPickerDialog.vue';

  // ライブラリ側で用意しているスタイルの読み込み
  import 'tui-image-editor/dist/tui-image-editor.css';
  import 'tui-color-picker/dist/tui-color-picker.css';

  // ダウンロード機能を使う際はインポートする
  import 'file-saver';

  // ロケールの設定。自分で日本語に訳して入れていく
  const localeJa = {
    // Load: 'ファイル選択',
    // Download: 'ダウンロード',
    // Apply: '適用',
    // Arrow: '矢印',
    // 'Arrow-2': '矢印2',
    // 'Arrow-3': '矢印3',
  };
  // 表示される各要素のスタイルを指定
  var theme = {
    'common.bi.image': '',
    'common.bisize.width': '0px',
    'common.bisize.height': '0px',
    'common.backgroundImage': 'none',
    'common.backgroundColor': '#1e1e1e',
    'common.border': '0px',

    // header
    'header.backgroundImage': 'none',
    'header.backgroundColor': 'transparent',
    'header.border': '0px',

    // load button
    'loadButton.backgroundColor': '#fff',
    'loadButton.border': '1px solid #ddd',
    'loadButton.color': '#222',
    'loadButton.fontFamily': "'M PLUS Rounded 1c', sans-serif", // フォントを変更
    'loadButton.fontSize': '12px',
    'loadButton.display': 'none',
    // download button
    'downloadButton.backgroundColor': '#fdba3b',
    'downloadButton.border': '1px solid #fdba3b',
    'downloadButton.color': '#fff',
    'downloadButton.fontFamily': "'M PLUS Rounded 1c', sans-serif", // フォントを変更
    'downloadButton.fontSize': '12px',
    'downloadButton.display': 'none',

    // main icons
    'menu.normalIcon.color': '#8a8a8a',
    'menu.activeIcon.color': '#555555',
    'menu.disabledIcon.color': '#434343',
    'menu.hoverIcon.color': '#e9e9e9',
    'menu.iconSize.width': '24px',
    'menu.iconSize.height': '24px',

    // submenu icons
    'submenu.normalIcon.color': '#8a8a8a',
    'submenu.activeIcon.color': '#e9e9e9',
    'submenu.iconSize.width': '32px',
    'submenu.iconSize.height': '32px',

    // submenu primary color
    'submenu.backgroundColor': '#1e1e1e',
    'submenu.partition.color': '#3c3c3c',

    // submenu labels
    'submenu.normalLabel.color': '#8a8a8a',
    'submenu.normalLabel.fontWeight': 'lighter',
    'submenu.activeLabel.color': '#fff',
    'submenu.activeLabel.fontWeight': 'lighter',

    // checkbox style
    'checkbox.border': '0px',
    'checkbox.backgroundColor': '#fff',
    // 'checkbox.border': '1px solid #ccc',
    // 'checkbox.backgroundColor': '#fff',

    // range style
    'range.pointer.color': '#fff',
    'range.bar.color': '#666',
    'range.subbar.color': '#d1d1d1',

    'range.disabledPointer.color': '#414141',
    'range.disabledBar.color': '#282828',
    'range.disabledSubbar.color': '#414141',

    'range.value.color': '#fff',
    'range.value.fontWeight': 'lighter',
    'range.value.fontSize': '11px',
    'range.value.border': '1px solid #353535',
    'range.value.backgroundColor': '#151515',
    'range.title.color': '#fff',
    'range.title.fontWeight': 'lighter',

    // colorpicker style
    'colorpicker.button.border': '1px solid #1e1e1e',
    'colorpicker.title.color': '#fff',
  };

  export default {
    name: 'GlamourRecipeMaker',
    metaInfo: {
      title: 'GlamourRecipeMaker',
      titleTemplate: '%s | FFXIV Housing Eden',
      meta: [
        { charset: 'utf-8' },
        {
          name: 'viewport',
          content: 'width=device-width, initial-scale=1',
        },
        {
          name: 'keywords',
          content:
            'ff14 ハウジング,ハウジング ff14,ff14 housing,housing ff14,ff14ハウジング,ff14housing,ハウジング,ハウジングエデン,ff14,ファイナルファンタジー,housing,ffxiv,final fantasy,Housing Eden,housing eden,画像加工ツール',
        },
        { property: 'og:type', content: 'website' },
        { name: 'twitter:card', content: 'summary_large_image' },
        { property: 'og:url', content: 'https://e-d-e-n.site' },
        { property: 'og:title', content: 'FFXIV Housing Eden' },
        {
          name: 'description',
          content:
            'ミラプリの図解用画像を作るためのツールです。装備アイコンやコピーライトの挿入がワンタッチでできます。',
        },
        {
          property: 'og:image',
          content: 'https://e-d-e-n.site/twitter_card.jpg?3',
        },
      ],
    },
    components: {
      'tui-image-editor': ImageEditor,
      ColorPickerDialog,
    },
    props: {},
    data() {
      return {
        selectedObjectId: null,
        snackbar: false, // スナックバーの表示状態
        postDialog: false, // 未ログイン投稿ダイアログの表示状態
        confirmationDialog: false, // 確認ダイアログの表示状態
        postTitle: '', // タイトルの入力内容
        postRemarks: '', // 備考の入力内容
        loginDialog: false, // ダイアログの表示状態
        selectedEquipment: [], // 選択された装備情報を保持する配列
        userInfo: null, // ユーザー情報
        selectedLanguage: this.$i18n.locale || 'en',
        textAlignment: 'left',
        sortDesc: true,
        // ジョブとロールの対応表
        availableJobs: {
          ja: [
            { name: 'ナイト', role: 'ファイター' },
            { name: '戦士', role: 'ファイター' },
            { name: '暗黒騎士', role: 'ファイター' },
            { name: 'ガンブレイカー', role: 'ファイター' },
            { name: '白魔道士', role: 'ソーサラー' },
            { name: '学者', role: 'ソーサラー' },
            { name: '占星術師', role: 'ソーサラー' },
            { name: '賢者', role: 'ソーサラー' },
            { name: 'モンク', role: 'ファイター' },
            { name: '竜騎士', role: 'ファイター' },
            { name: '忍者', role: 'ファイター' },
            { name: '侍', role: 'ファイター' },
            { name: 'リーパー', role: 'ファイター' },
            { name: 'ヴァイパー', role: 'ファイター' },
            { name: '吟遊詩人', role: 'ファイター' },
            { name: '機工士', role: 'ファイター' },
            { name: '踊り子', role: 'ファイター' },
            { name: '黒魔道士', role: 'ソーサラー' },
            { name: '召喚士', role: 'ソーサラー' },
            { name: '赤魔道士', role: 'ソーサラー' },
            { name: '青魔道士', role: 'ソーサラー' },
            { name: 'ピクトマンサー', role: 'ソーサラー' },
            { name: '木工師', role: 'クラフター' },
            { name: '鍛冶師', role: 'クラフター' },
            { name: '甲冑師', role: 'クラフター' },
            { name: '彫金師', role: 'クラフター' },
            { name: '革細工師', role: 'クラフター' },
            { name: '裁縫師', role: 'クラフター' },
            { name: '錬金術師', role: 'クラフター' },
            { name: '調理師', role: 'クラフター' },
            { name: '採掘師', role: 'ギャザラー' },
            { name: '園芸師', role: 'ギャザラー' },
            { name: '漁師', role: 'ギャザラー' },
          ],
          en: [
            { name: 'PLD', role: 'War' },
            { name: 'WAR', role: 'War' },
            { name: 'DRK', role: 'War' },
            { name: 'GNB', role: 'War' },
            { name: 'WHM', role: 'Magic' },
            { name: 'SCH', role: 'Magic' },
            { name: 'AST', role: 'Magic' },
            { name: 'SGE', role: 'Magic' },
            { name: 'MNK', role: 'War' },
            { name: 'DRG', role: 'War' },
            { name: 'NIN', role: 'War' },
            { name: 'SAM', role: 'War' },
            { name: 'RPR', role: 'War' },
            { name: 'VPR', role: 'War' },
            { name: 'BRD', role: 'War' },
            { name: 'MCH', role: 'War' },
            { name: 'DNC', role: 'War' },
            { name: 'BLM', role: 'Magic' },
            { name: 'SMN', role: 'Magic' },
            { name: 'RDM', role: 'Magic' },
            { name: 'BLU', role: 'Magic' },
            { name: 'PCT', role: 'Magic' },
            { name: 'CRP', role: 'Hand' },
            { name: 'BSM', role: 'Hand' },
            { name: 'ARM', role: 'Hand' },
            { name: 'GSM', role: 'Hand' },
            { name: 'LTW', role: 'Hand' },
            { name: 'WVR', role: 'Hand' },
            { name: 'ALC', role: 'Hand' },
            { name: 'CUL', role: 'Hand' },
            { name: 'MIN', role: 'Land' },
            { name: 'BTN', role: 'Land' },
            { name: 'FSH', role: 'Land' },
          ],
        },
        selectedEquipmentTypes: [], // 選択された装備タイプを保持する配列
        selectedJobs: [], // 選択されたジョブを保持する配列を定義
        selectedRole: '', // 選択されたロール（タンク、ヒーラー、DPSなど）
        selectedColors: {}, // 各アイテムごとの色を保存
        selectedColorNames: {}, // 各アイテムごとの色の名前を保存
        panels: {
          en: [
            {
              title: 'Arms',
              typeArray: [
                "Gunbreaker's Arm",
                "Dark Knight's Arm",
                "Arcanist's Grimoire",
                "Scholar's Arm",
                "Marauder's Arm",
                "Lancer's Arm",
                "Reaper's Arm",
                "Rogue's Arm",
                "Samurai's Arm",
                "Ninja's Arm",
                "Astrologian's Arm",
                "Archer's Arm",
                "Pugilist's Arm",
                "Gladiator's Arm",
                "One-handed Conjurer's Arm",
                "One-handed Thaumaturge's Arm",
                "Red Mage's Arm",
                "Machinist's Arm",
                "Sage's Arm",
                "Blue Mage's Arm",
                "Two-handed Conjurer's Arm",
                "Two-handed Thaumaturge's Arm",
              ],
            },
            {
              title: 'Tools',
              typeArray: [
                "Botanist's Primary Tool",
                "Goldsmith's Primary Tool",
                "Miner's Primary Tool",
                "Carpenter's Primary Tool",
                "Fisher's Primary Tool",
                "Armorer's Primary Tool",
                "Weaver's Primary Tool",
                "Culinarian's Primary Tool",
                "Alchemist's Primary Tool",
                "Blacksmith's Primary Tool",
                "Leatherworker's Primary Tool",
              ],
            },
            {
              title: 'Armor',
              typeArray: ['Head', 'Body', 'Hands', 'Legs', 'Feet'],
            },
            {
              title: 'Accessories',
              typeArray: ['Rings', 'Earrings', 'Bracelets', 'Necklaces'],
            },
          ],
          ja: [
            {
              title: '武器',
              typeArray: [
                'ガンブレード',
                '両手剣',
                '両手呪具',
                '両手幻具',
                '両手斧',
                '両手槍',
                '両手鎌',
                '二刀流武器',
                '刀',
                '双剣',
                '天球儀',
                '弓',
                '格闘武器',
                '片手剣',
                '片手呪具',
                '片手幻具',
                '細剣',
                '銃',
                '賢具',
                '青魔器',
                '魔道書',
                '魔道書(学者専用)',
              ],
            },
            {
              title: '道具',
              typeArray: [
                '園芸道具(主道具)',
                '彫金道具(主道具)',
                '採掘道具(主道具)',
                '木工道具(主道具)',
                '漁道具(主道具)',
                '甲冑道具(主道具)',
                '裁縫道具(主道具)',
                '調理道具(主道具)',
                '錬金道具(主道具)',
                '鍛冶道具(主道具)',
                '革細工道具(主道具)',
              ],
            },
            {
              title: '防具',
              typeArray: ['頭防具', '胴防具', '手防具', '脚防具', '足防具'],
            },
            {
              title: 'アクセサリ',
              typeArray: ['指輪', '耳飾り', '腕輪', '首飾り'],
            },
          ],
          ko: [
            {
              title: 'Arms',
              typeArray: [
                "Gunbreaker's Arm",
                "Dark Knight's Arm",
                "Arcanist's Grimoire",
                "Scholar's Arm",
                "Marauder's Arm",
                "Lancer's Arm",
                "Reaper's Arm",
                "Rogue's Arm",
                "Samurai's Arm",
                "Ninja's Arm",
                "Astrologian's Arm",
                "Archer's Arm",
                "Pugilist's Arm",
                "Gladiator's Arm",
                "One-handed Conjurer's Arm",
                "One-handed Thaumaturge's Arm",
                "Red Mage's Arm",
                "Machinist's Arm",
                "Sage's Arm",
                "Blue Mage's Arm",
                "Two-handed Conjurer's Arm",
                "Two-handed Thaumaturge's Arm",
              ],
            },
            {
              title: 'Tools',
              typeArray: [
                "Botanist's Primary Tool",
                "Goldsmith's Primary Tool",
                "Miner's Primary Tool",
                "Carpenter's Primary Tool",
                "Fisher's Primary Tool",
                "Armorer's Primary Tool",
                "Weaver's Primary Tool",
                "Culinarian's Primary Tool",
                "Alchemist's Primary Tool",
                "Blacksmith's Primary Tool",
                "Leatherworker's Primary Tool",
              ],
            },
            {
              title: 'Armor',
              typeArray: ['Head', 'Body', 'Hands', 'Legs', 'Feet'],
            },
            {
              title: 'Accessories',
              typeArray: ['Rings', 'Earrings', 'Bracelets', 'Necklaces'],
            },
          ],
          zh: [
            {
              title: 'Arms',
              typeArray: [
                "Gunbreaker's Arm",
                "Dark Knight's Arm",
                "Arcanist's Grimoire",
                "Scholar's Arm",
                "Marauder's Arm",
                "Lancer's Arm",
                "Reaper's Arm",
                "Rogue's Arm",
                "Samurai's Arm",
                "Ninja's Arm",
                "Astrologian's Arm",
                "Archer's Arm",
                "Pugilist's Arm",
                "Gladiator's Arm",
                "One-handed Conjurer's Arm",
                "One-handed Thaumaturge's Arm",
                "Red Mage's Arm",
                "Machinist's Arm",
                "Sage's Arm",
                "Blue Mage's Arm",
                "Two-handed Conjurer's Arm",
                "Two-handed Thaumaturge's Arm",
              ],
            },
            {
              title: 'Tools',
              typeArray: [
                "Botanist's Primary Tool",
                "Goldsmith's Primary Tool",
                "Miner's Primary Tool",
                "Carpenter's Primary Tool",
                "Fisher's Primary Tool",
                "Armorer's Primary Tool",
                "Weaver's Primary Tool",
                "Culinarian's Primary Tool",
                "Alchemist's Primary Tool",
                "Blacksmith's Primary Tool",
                "Leatherworker's Primary Tool",
              ],
            },
            {
              title: 'Armor',
              typeArray: ['Head', 'Body', 'Hands', 'Legs', 'Feet'],
            },
            {
              title: 'Accessories',
              typeArray: ['Rings', 'Earrings', 'Bracelets', 'Necklaces'],
            },
          ],
        },

        items: [],
        rawItems: [],
        id: '',
        search: '', // 実際に検索に使用されるクエリ
        page: 1,
        // typeArray: [
        //   'All',
        //   '盾',
        //   '頭防具',
        //   '胴防具',
        //   '手防具',
        //   '脚防具',
        //   '足防具',
        //   '耳飾り',
        //   '首飾り',
        //   '腕輪',
        //   '指輪 ',
        // ],
        useDefaultUI: true,
        autoName: true,
        options: {
          cssMaxWidth:
            document.documentElement.clientWidth < 600
              ? document.documentElement.clientWidth - 30
              : document.documentElement.clientWidth - 300,
          cssMaxHeight: document.documentElement.clientHeight * 0.65,
          selectionStyle: {
            cornerStyle: 'circle',
            cornerSize: 50, // スマホのときは60、PCは20
            transparentCorners: false, // 透明をオフ,
          },
          includeUI: {
            loadImage: {
              path: 'glamour.jpg',
              name: 'SampleImage',
            },
            // 表示メニューの設定。デフォルトでは全て表示される
            menu: ['crop', 'rotate', 'draw', 'text'],
            // コンポーネント生成時に選択されるメニューの指定
            initMenu: '',
            // メニューバーの位置の指定
            menuBarPosition: 'bottom',
            uiSize: {
              width: '100%',
              height: this.$vuetify.breakpoint.xsOnly ? '80vh' : '80vh', // スマホでは50%、それ以外では100%
            },
            theme: theme,
            locale: localeJa,
          },
        },
      };
    },
    computed: {
      // 使用するジョブを現在の言語に応じて取得
      localizedAvailableJobs() {
        return (
          this.availableJobs[this.selectedLanguage] || this.availableJobs.en
        );
      },
      filteredItems() {
        return this.items.filter((item) => {
          // 装備タイプが選択されている場合の条件
          const equipmentTypeMatch =
            this.selectedEquipmentTypes.length === 0 ||
            this.selectedEquipmentTypes.includes(item.type);

          // ジョブが選択されている場合の条件
          const jobMatch =
            this.selectedJobs.length === 0 ||
            this.selectedJobs.some((selectedJob) => {
              const selectedJobRole = this.getJobRole(selectedJob); // 選択されたジョブのロールを取得

              // item.jobs にジョブ名、全クラス、またはロール名が含まれている場合
              return (
                item.jobs.includes(selectedJob) ||
                item.jobs.includes('全クラス') ||
                (selectedJobRole && item.jobs.includes(selectedJobRole))
              );
            });

          // 検索クエリが指定されている場合の条件
          const searchMatch =
            !this.search ||
            item.name.toLowerCase().includes(this.search.toLowerCase());

          // ジョブのフィルタリングに合致し、かつ検索条件にも合致するアイテムのみ表示
          return jobMatch && searchMatch && equipmentTypeMatch;
        });
      },
    },
    watch: {
      selectedColors: {
        deep: true, // 深いウォッチを行うことでオブジェクトの内部の変化もキャッチ
        handler(newSelectedColors) {
          for (const itemName in newSelectedColors) {
            this.updateSelectedColorName(itemName, newSelectedColors[itemName]);
          }
        },
      },
    },
    created: function () {
      this.items.forEach((item) => {
        if (!this.selectedColors[item.name]) {
          this.$set(this.selectedColors, item.name, ''); // 初期値を空文字で設定
        }
      });
      switch (this.$i18n.locale) {
        case 'ja':
          axios
            .get(
              `../../GlamourRecipe/equipments_ja.json?timestamp=${new Date().getTime()}`
            )
            .then((res) => {
              this.items = res.data;
              this.rawItems = res.data;
              this.typeArray = ['All'];
            });
          break;
        case 'en':
          axios
            .get(
              `../../GlamourRecipe/equipments_en.json?timestamp=${new Date().getTime()}`
            )
            .then((res) => {
              this.items = res.data;
              this.rawItems = res.data;
              this.typeArray = ['All'];
            });
          break;
        // case 'ko':
        //   axios
        //     .get(`../../furnishings_ko.json?timestamp=${new Date().getTime()}`)
        //     .then((res) => {
        //       this.items = res.data;
        //       this.rawItems = res.data;
        //       this.typeArray = [
        //         'All',
        //         '가구',
        //         '소품(받침대)',
        //         '소품(탁상)',
        //         '소품(벽걸이)',
        //         '소품(깔개)',
        //         '내장 건축제(내벽)',
        //         '내장 건축제(천장 조명)',
        //         '내장 건축제(바닥)',
        //       ];
        //       for (const item of this.items) {
        //         item.num = '';
        //       }
        //     });
        //   break;
        default:
          axios
            .get(
              `../../GlamourDiagramMaker/GlamourDiagramMaker_ja.json?timestamp=${new Date().getTime()}`
            )
            .then((res) => {
              this.items = res.data;
              this.rawItems = res.data;
              this.typeArray = ['All'];
            });
          break;
      }
    },
    mounted() {
      console.log('mounted'); // ここでrefsが期待通りか確認
      console.log(this.$refs); // ここでrefsが期待通りか確認

      console.log('ビューポートの幅:', document.documentElement.clientWidth);
      console.log('ビューポートの高さ:', document.documentElement.clientHeight);
    },
    methods: {
      objectActivated(props) {
        console.log(props);
        this.selectedObjectId = props.id;
      },
      // 拡大
      scaleUp() {
        if (this.selectedObjectId) {
          console.log('selectedObjectId:' + this.selectedObjectId);

          // 選択されたオブジェクトのプロパティを取得
          const activeObject = this.$refs.tuiImageEditor.invoke(
            'getObjectProperties',
            this.selectedObjectId,
            ['scaleX', 'scaleY']
          );

          // 現在のオブジェクトのスケール値を取得
          const currentScaleX = activeObject.scaleX || 1; // scaleXのデフォルト値は1
          const currentScaleY = activeObject.scaleY || 1; // scaleYのデフォルト値は1

          // 新しいスケール値を設定 (1.1倍に拡大)
          const newScaleX = currentScaleX * 1.1;
          const newScaleY = currentScaleY * 1.1;

          // オブジェクト自体のスケールを設定
          this.$refs.tuiImageEditor.invoke(
            'setObjectProperties',
            this.selectedObjectId,
            {
              scaleX: newScaleX,
              scaleY: newScaleY,
            }
          );
        } else {
          console.log('オブジェクトが選択されていません');
        }
      },

      // 縮小
      scaleDown() {
        if (this.selectedObjectId) {
          console.log('selectedObjectId:' + this.selectedObjectId);

          // 選択されたオブジェクトのプロパティを取得
          const activeObject = this.$refs.tuiImageEditor.invoke(
            'getObjectProperties',
            this.selectedObjectId,
            ['scaleX', 'scaleY']
          );

          // 現在のオブジェクトのスケール値を取得
          const currentScaleX = activeObject.scaleX || 1; // scaleXのデフォルト値は1
          const currentScaleY = activeObject.scaleY || 1; // scaleYのデフォルト値は1

          // 新しいスケール値を設定 (0.9倍に縮小)
          const newScaleX = currentScaleX * 0.9;
          const newScaleY = currentScaleY * 0.9;

          // オブジェクト自体のスケールを設定
          this.$refs.tuiImageEditor.invoke(
            'setObjectProperties',
            this.selectedObjectId,
            {
              scaleX: newScaleX,
              scaleY: newScaleY,
            }
          );
        } else {
          console.log('オブジェクトが選択されていません');
        }
      },
      // 投稿前の確認ダイアログを表示
      openConfirmationDialog() {
        // ログイン状態をチェック
        if (!this.$auth.isAuthenticated) {
          // ログインしていない場合、ダイアログを表示
          this.postDialog = true;
        } else {
          // ログインしている場合、ダイアログを開く
          this.confirmationDialog = true;
        }
      },
      // 投稿をキャンセル
      cancelPost() {
        this.confirmationDialog = false;
      },
      // 投稿を確定
      confirmPost() {
        this.confirmationDialog = false;
        this.postToDiscord(); // 実際のDiscord投稿処理
      },
      // Discordにミラプリレシピを投稿する
      postToDiscord: function () {
        var dataURL = this.$refs.tuiImageEditor.invoke('toDataURL');
        this.sendImageAndUserInfoToDiscord(dataURL);
      },

      // Twitterにハッシュタグ付きでツイートする
      postToTwitter: function () {
        const tweetText = '';
        const encodedText = encodeURIComponent(tweetText);
        const encodedTag = encodeURIComponent('#ミラプリレシピ');
        const twitterUrl = `https://twitter.com/intent/tweet?text=${encodedText}%20${encodedTag}`;

        window.open(twitterUrl, '_blank'); // 新しいタブでツイートページを開く
      },

      // Discordに画像とユーザー情報、装備情報を送信する
      sendImageAndUserInfoToDiscord: async function (dataURL) {
        const webhookURL =
          'https://discord.com/api/webhooks/1283787301016571964/YtN_GbuyJKiiHM6oSMzqHHc0yLS50-d1UAqqd3JC3oxxy1YZT-g0Iu9YaX5xw8Cgv_m_';

        const formData = new FormData();
        const blob = this.base64ToBlob(dataURL);
        // 一意のファイル名を作成（タイムスタンプとランダムなIDを使用）
        const uniqueId =
          Date.now() + '-' + Math.random().toString(36).substring(7);
        const fileName = `glamour_recipe_${uniqueId}.jpeg`; // 一意のファイル名を生成
        formData.append('file', blob, fileName);

        // ユーザー情報を取得
        this.userInfo = this.$auth.user || {}; // undefined対策でデフォルト値に空オブジェクト
        const username = this.userInfo.name || '不明なユーザー';

        // 装備情報をJSON形式でcontentに追加
        const equipmentDetails = this.selectedEquipment.map((equipment) => ({
          name: equipment.name,
          imageUrl: equipment.imageUrl,
          colors: equipment.colors,
          colorNames: equipment.colorNames,
        }));

        // トークンをデコードしてカスタムクレームからメタデータを取得
        const decodedToken = await this.$auth.getIdTokenClaims();

        // カスタムクレームからscreen_nameを取得
        const userMetadata =
          decodedToken['https://housingeden.user.metadata/user_metadata'];

        if (userMetadata) {
          // screen_nameをtwitterIdに設定
          this.twitterId = userMetadata.screen_name;
          console.log('Twitter ID set to:', this.twitterId);
        } else {
          console.error('No user metadata found in token');
        }

        // ユーザー情報と装備情報をマージ
        const mergedData = {
          messageType: 'postGlamourRecipe',
          title: this.postTitle || '無題の投稿', // タイトルが空の場合はデフォルトのタイトルを使用
          remarks: this.postRemarks || '', // 備考が空の場合は空文字
          user: {
            name: username,
            nickname: this.userInfo.nickname || '不明なニックネーム',
            picture: this.userInfo.picture || '',
            twitterId: this.twitterId || '',
            userMetadata: this.userMetadata || '',
          },
          equipment: equipmentDetails,
        };

        const message = {
          content: `${JSON.stringify(mergedData, null, 2)}`, // マージされたデータをcontentに追加
          embeds: [
            {
              title: 'ミラプリレシピ投稿',
              description: `ユーザー: ${username}`,
              image: { url: 'attachment://' + fileName },
            },
          ],
        };

        formData.append('payload_json', JSON.stringify(message));

        return axios
          .post(webhookURL, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
          .then((response) => {
            console.log('Discordに送信成功:', response.data);
            this.snackbar = true; // 送信成功後にスナックバーを表示
          })
          .catch((error) => {
            console.error('Discordへの送信失敗', error);
          });
      },

      // 言語が変わったときに呼び出す
      changeLanguage(newLanguage) {
        this.selectedLanguage = newLanguage;
      },
      triggerFileInput() {
        // ログイン状態をチェック
        if (!this.$auth.isAuthenticated) {
          // ログインしていない場合、ダイアログを表示
          this.loginDialog = true;
        } else {
          // ログインしている場合、ファイル選択ダイアログを開く
          this.$refs.fileInput.click();
        }
      },
      confirmLogin() {
        // ユーザーがログインを承認した場合、ログイン処理を行う
        this.$auth.loginWithRedirect({
          appState: {
            targetUrl: '/components/glamour-recipe-maker/',
          },
        });
        this.loginDialog = false;
      },
      cancelLogin() {
        this.$refs.fileInput.click();
        this.loginDialog = false;
      },

      isSelectedJob(job) {
        return this.selectedJobs.includes(job);
      },
      getJobChipBorderColor(job) {
        // 各ロールごとにジョブを分類して色を設定
        // 各ロールごとにジョブを分類して色を設定
        const tankJobs = [
          'ナイト',
          '戦士',
          '暗黒騎士',
          'ガンブレイカー',
          'PLD',
          'WAR',
          'DRK',
          'GNB',
        ];
        const healerJobs = [
          '白魔道士',
          '学者',
          '占星術師',
          '賢者',
          'WHM',
          'SCH',
          'AST',
          'SGE',
        ];
        const dpsJobs = [
          'モンク',
          '竜騎士',
          '忍者',
          '侍',
          'リーパー',
          '吟遊詩人',
          '機工士',
          '踊り子',
          '黒魔道士',
          '召喚士',
          '赤魔道士',
          'ヴァイパー',
          '青魔道士',
          'ピクトマンサー',
          'MNK',
          'DRG',
          'NIN',
          'SAM',
          'RPR',
          'BRD',
          'MCH',
          'DNC',
          'BLM',
          'SMN',
          'RDM',
          'VPR',
          'BLU',
          'PCT',
        ];
        const crafterJobs = [
          '木工師',
          '鍛冶師',
          '甲冑師',
          '彫金師',
          '革細工師',
          '裁縫師',
          '錬金術師',
          '調理師',
          'CRP',
          'BSM',
          'ARM',
          'GSM',
          'LTW',
          'WVR',
          'ALC',
          'CUL',
        ];
        const gathererJobs = ['採掘師', '園芸師', '漁師', 'MIN', 'BTN', 'FSH'];
        // タンクロールの場合
        if (tankJobs.includes(job)) {
          return 'blue'; // タンクは紫色
        }

        // ヒーラーロールの場合
        if (healerJobs.includes(job)) {
          return 'green'; // ヒーラーは緑色
        }

        // DPSロールの場合
        if (dpsJobs.includes(job)) {
          return 'red'; // DPSはオレンジ色
        }

        // クラフターロールの場合
        if (crafterJobs.includes(job)) {
          return 'purple'; // クラフターは紫色
        }

        // ギャザラーロールの場合
        if (gathererJobs.includes(job)) {
          return 'brown'; // ギャザラーは肌色 (ウェジウッドカラー)
        }

        // その他（例えばクラフターやギャザラー）に対してのデフォルト色
        return 'gray'; // デフォルトの色
      },
      clearFilters() {
        // フィルターをクリア
        this.selectedEquipmentTypes = [];
        this.selectedJobs = [];
        // this.updateItems(); // 全アイテムを表示
      },
      // 指定されたジョブのロールを取得するメソッド
      getJobRole(jobName) {
        const job = this.localizedAvailableJobs.find(
          (job) => job.name === jobName
        );
        return job ? job.role : null;
      },
      hexToRgb(hex) {
        // 16進数の色コードをRGBに変換
        const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        return result
          ? {
              r: parseInt(result[1], 16),
              g: parseInt(result[2], 16),
              b: parseInt(result[3], 16),
            }
          : { r: 0, g: 0, b: 0 }; // デフォルト値として黒を返す
      },
      getLuminance(hexColor) {
        const rgb = this.hexToRgb(hexColor);
        const luminance =
          (0.2126 * rgb.r + 0.7152 * rgb.g + 0.0722 * rgb.b) / 255;
        return luminance;
      },
      getTextColor(bgColor) {
        if (!bgColor) return '#000'; // デフォルトで黒色
        const rgb = this.hexToRgb(bgColor);
        // 明るさを計算 (Luminance Calculation)
        const brightness = (rgb.r * 299 + rgb.g * 587 + rgb.b * 114) / 1000;
        // 明るさが一定値より低ければ白色、そうでなければ黒色
        return brightness < 128 ? '#fff' : '#000';
      },
      openColorPicker(itemName, index) {
        const colorPickerRef = this.$refs['colorPicker_' + itemName];
        if (colorPickerRef && typeof colorPickerRef[0].open === 'function') {
          colorPickerRef[0].open(index); // インデックスを渡して色選択を開く
        }
      },
      // 色とインデックスを受け取って selectedColors に反映
      updateSelectedColor(itemName, selectedColor, index) {
        // selectedColors[item.name] が存在しない場合は配列として初期化
        if (!Array.isArray(this.selectedColors[itemName])) {
          this.$set(this.selectedColors, itemName, []);
        }
        if (!Array.isArray(this.selectedColorNames[itemName])) {
          this.$set(this.selectedColorNames, itemName, []);
        }

        // 正常な色かどうかをチェック
        if (selectedColor && selectedColor.color && selectedColor.name) {
          this.$set(this.selectedColors[itemName], index, selectedColor.color);
          this.$set(
            this.selectedColorNames[itemName],
            index,
            selectedColor.name
          );
        } else {
          // nameが空の場合、デフォルトの名前を設定する
          const defaultColorName = 'Jet Black';
          this.$set(this.selectedColors, itemName, index, selectedColor.color);
          this.$set(
            this.selectedColorNames,
            itemName,
            index,
            selectedColor.name || defaultColorName
          );
        }
      },
      updateSelectedColorName(itemName, color) {
        const swatch = this.getSelectedSwatch(color);
        if (swatch) {
          this.$set(this.selectedColorNames, itemName, swatch.name);
        }
      },
      getSelectedSwatch(color) {
        for (const key in this.$refs) {
          if (key.startsWith('colorPicker_')) {
            const picker = this.$refs[key][0];
            if (picker && picker.swatches) {
              const foundSwatch = picker.swatches.find(
                (swatch) => swatch.color === color
              );
              if (foundSwatch) return foundSwatch;
            }
          }
        }
        return null;
      },
      toggleEquipmentTypeFilter(equipmentType) {
        const index = this.selectedEquipmentTypes.indexOf(equipmentType);
        if (index === -1) {
          this.selectedEquipmentTypes.push(equipmentType);
        } else {
          this.selectedEquipmentTypes.splice(index, 1);
        }
        // this.updateItems();
      },
      toggleJobFilter(job) {
        const index = this.selectedJobs.indexOf(job);
        if (index === -1) {
          this.selectedJobs.push(job);
        } else {
          this.selectedJobs.splice(index, 1);
        }
        // this.updateItems();
      },
      // updateItems() {
      //   // フィルタ指定がない場合は全アイテムを返す
      //   if (
      //     this.selectedEquipmentTypes.length === 0 &&
      //     this.selectedJobs.length === 0
      //   ) {
      //     this.items = this.rawItems;
      //     return;
      //   }

      //   // フィルタ指定がある場合にフィルタリング
      //   this.items = this.rawItems.filter((item) => {
      //     const equipmentMatch =
      //       this.selectedEquipmentTypes.length === 0 ||
      //       this.selectedEquipmentTypes.includes(item.type);

      //     const jobMatch =
      //       this.selectedJobs.length === 0 ||
      //       this.selectedJobs.some((job) => item.jobs.includes(job));

      //     return equipmentMatch && jobMatch;
      //   });

      //   console.log('Filtered Items:', this.items);
      // },
      makeIcon: function (imageUrl, name, autoName) {
        console.log('autoName:' + autoName);
        const filename = imageUrl.substring(imageUrl.lastIndexOf('/') + 1);
        console.log(filename);
        var url = '../../img/equipments/' + filename;
        console.log(url);

        var image = new Image();
        image.crossOrigin = 'use-credentials'; // または 'use-credentials' 必要に応じて
        image.src = url;
        image.onload = function () {
          // 画像ロードが完了してからキャンバスの準備をする
          var canvas = document.getElementById('preview');
          var ctx = canvas.getContext('2d');

          // キャンバスのサイズを画像サイズに合わせる
          canvas.width = 128 + 30;
          canvas.height = 128;

          // 名前と色名を表示するために高さを調整
          if (autoName === true) {
            const nameHeight = 30; // 名前の表示に必要な高さ
            const colorNameHeight = 30 * 2; // 2つの色名を表示するための高さ

            // 名前と色名を描画するためのスペースを追加
            canvas.height = canvas.height + nameHeight + colorNameHeight;

            // 名前と色名の幅を取得
            const nameWidth = ctx.measureText(name).width;
            const colorName1Width =
              _this.selectedColorNames[name] &&
              _this.selectedColorNames[name][0]
                ? ctx.measureText(_this.selectedColorNames[name][0]).width
                : 0; // デフォルト幅は0
            const colorName2Width =
              _this.selectedColorNames[name] &&
              _this.selectedColorNames[name][1]
                ? ctx.measureText(_this.selectedColorNames[name][1]).width
                : 0; // デフォルト幅は0

            // 両方の幅を比較して大きい方を使用
            const maxWidth = Math.max(
              nameWidth,
              colorName1Width,
              colorName2Width
            );

            if (maxWidth * 2 > canvas.width) {
              canvas.width = maxWidth * 2 + 20;
            }
          }

          // 文字のスタイルを指定
          ctx.font = '20px "M PLUS Rounded 1c", sans-serif';
          ctx.lineWidth = 10;
          ctx.lineJoin = 'miter';
          ctx.miterLimit = '5';
          // キャンバスに画像を描画（開始位置0,0）
          console.log('textAlignment');
          console.log(_this.textAlignment);
          var imageX =
            _this.textAlignment === 'left'
              ? 0 // 左寄せ
              : _this.textAlignment === 'center'
              ? (canvas.width - image.width) / 2 // 中央寄せ
              : canvas.width - image.width; // 右寄せ

          ctx.drawImage(image, imageX, 0); // 画像を描画（X座標は imageX, Y座標は 0）

          // 名前の描画
          if (autoName === true) {
            ctx.textBaseline = 'bottom';
            ctx.textAlign = _this.textAlignment;
            ctx.fillStyle = '#ffffff';
            var nameY = canvas.height - 65; // 名前の位置
            var nameX =
              _this.textAlignment === 'left'
                ? 0 // 左寄せ
                : _this.textAlignment === 'center'
                ? canvas.width / 2 // 中央寄せ
                : canvas.width; // 右寄せ

            ctx.strokeText(name, nameX, nameY); // 名前を左寄せで描画
            ctx.fillText(name, nameX, nameY);
          }

          // 色名の描画（2色分）
          const colors = _this.selectedColors[name] || []; // selectedColors が存在しない場合は空配列を使用
          const colorNames = _this.selectedColorNames[name] || []; // selectedColorNames が存在しない場合は空配列を使用
          const backgroundHeight = 25; // 背景の高さを定義

          if (colors.length > 0 && colorNames.length > 0) {
            ctx.font = '16px "M PLUS Rounded 1c", sans-serif';
            ctx.lineWidth = 10;
            ctx.textAlign = _this.textAlignment;
            ctx.textBaseline = 'middle';

            // 色名1の背景と色名を描画
            if (colors[0] && colorNames[0]) {
              const textWidth1 = ctx.measureText(colorNames[0]).width;
              const padding = 10;
              const backgroundWidth1 = textWidth1 + padding * 2;

              const backgroundY1 = canvas.height - 60; // 色名1の位置
              const backgroundX1 =
                _this.textAlignment === 'left'
                  ? 0 // 左寄せ
                  : _this.textAlignment === 'center'
                  ? (canvas.width - backgroundWidth1) / 2 // 中央寄せ
                  : canvas.width - backgroundWidth1; // 右寄せ

              // 色名1の背景
              ctx.fillStyle = colors[0];
              ctx.fillRect(
                backgroundX1,
                backgroundY1,
                backgroundWidth1,
                backgroundHeight
              );

              // 色名1のテキスト
              ctx.fillStyle = '#ffffff';
              ctx.strokeStyle = '#000000';
              const colorTextX =
                _this.textAlignment === 'left'
                  ? padding // 左寄せの場合
                  : _this.textAlignment === 'center'
                  ? canvas.width / 2 // 中央寄せの場合
                  : canvas.width - padding; // 右寄せの場合
              ctx.strokeText(
                colorNames[0],
                colorTextX,
                backgroundY1 + backgroundHeight / 2
              );
              ctx.fillText(
                colorNames[0],
                colorTextX,
                backgroundY1 + backgroundHeight / 2
              );
            }

            // 色名2の背景と色名を描画
            if (colors[1] && colorNames[1]) {
              const textWidth2 = ctx.measureText(colorNames[1]).width;
              const padding = 10;
              const backgroundWidth2 = textWidth2 + padding * 2;

              const backgroundY2 = canvas.height - 30; // 色名2の位置
              const backgroundX2 =
                _this.textAlignment === 'left'
                  ? 0 // 左寄せ
                  : _this.textAlignment === 'center'
                  ? (canvas.width - backgroundWidth2) / 2 // 中央寄せ
                  : canvas.width - backgroundWidth2; // 右寄せ
              // 色名2の背景
              ctx.fillStyle = colors[1];
              ctx.fillRect(
                backgroundX2,
                backgroundY2,
                backgroundWidth2,
                backgroundHeight
              );

              // 色名2のテキスト
              ctx.fillStyle = '#ffffff';
              ctx.strokeStyle = '#000000';
              const colorTextX =
                _this.textAlignment === 'left'
                  ? padding // 左寄せの場合
                  : _this.textAlignment === 'center'
                  ? canvas.width / 2 // 中央寄せの場合
                  : canvas.width - padding; // 右寄せの場合
              ctx.strokeText(
                colorNames[1],
                colorTextX,
                backgroundY2 + backgroundHeight / 2
              );
              ctx.fillText(
                colorNames[1],
                colorTextX,
                backgroundY2 + backgroundHeight / 2
              );
            }
          }

          // キャンバスの拡大処理
          var scale = 2; // 2倍に拡大する
          var tempCanvas = document.createElement('canvas');
          var tempCtx = tempCanvas.getContext('2d');

          // 元のキャンバスのサイズに合わせて一旦新しいキャンバスを設定
          tempCanvas.width = canvas.width * scale;
          tempCanvas.height = canvas.height * scale;

          // スケールを適用して元のキャンバスの内容を描画
          tempCtx.scale(scale, scale);
          tempCtx.drawImage(canvas, 0, 0);

          // 拡大されたキャンバスのデータを使ってアイコンを作成
          _this.addIcon(tempCanvas.toDataURL()); // 拡大された内容を使用

          // 装備情報をselectedEquipmentに保存
          _this.selectedEquipment.push({
            name: name,
            imageUrl: url,
            colors: _this.selectedColors[name] || [],
            colorNames: _this.selectedColorNames[name] || [],
          });

          // 最後にスクロール処理を追加
          window.scrollTo({
            top: 0, // ページの一番上にスクロール
            behavior: 'smooth', // スムーズにスクロールする
          });
        };
        const _this = this;
      },
      addIcon: function (url) {
        this.$refs.tuiImageEditor
          .invoke('addImageObject', url)
          .then((objectProps) => {
            console.log('objectProps:' + objectProps);
            console.log(objectProps.id);
            this.id = objectProps.id;
            console.log(this.id);
          })
          .catch((error) => {
            console.error('Error adding image object:', error);
          });
      },

      addCopyright: function () {
        this.$refs.tuiImageEditor.invoke('addText', '© SQUARE ENIX', {
          styles: {
            fill: '#fff',
            fontSize: 30,
            fontWeight: 'bold',
            fontFamily: "'M PLUS Rounded 1c', sans-serif",
            stroke: '#808080', // 縁取りの色（灰色）
            strokeWidth: 3, // 縁取りの太さ
          },
        });
      },
      // setTimeout(() => {
      //   console.log(this.id)
      //   this.$refs.tuiImageEditor.invoke('setObjectProperties', (this.id, {
      //     left: 100,
      //     top: 100,
      //     width: 200,
      //     height: 200,
      //     opacity: 0.5,
      //   }))
      // }, 100)
      base64ToBlob: function (data) {
        var raw, uInt8Array, i, rawLength;

        // Base64データから"data:image/png;base64,"のような部分を削除する
        raw = data.replace(/^data:image\/(png|jpeg);base64,/, ''); // PNGまたはJPEGデータを扱えるように
        raw = atob(raw);
        rawLength = raw.length;
        uInt8Array = new Uint8Array(rawLength); // eslint-disable-line

        for (i = 0; i < rawLength; i += 1) {
          uInt8Array[i] = raw.charCodeAt(i);
        }

        // MIMEタイプをimage/jpegに固定してJPEG形式でBlobを作成
        return new Blob([uInt8Array], { type: 'image/jpeg' });
      },

      inputImage: function (event) {
        console.log(event);
        var file;
        var supportingFileAPI = !!(
          window.File &&
          window.FileList &&
          window.FileReader
        );

        if (!supportingFileAPI) {
          alert('This browser does not support file-api');
        }

        file = event.target.files[0];

        if (!file) return;

        var img = new Image();
        var reader = new FileReader();

        reader.onload = (e) => {
          img.src = e.target.result;
        };

        img.onload = () => {
          var canvas = document.createElement('canvas');
          var ctx = canvas.getContext('2d');

          // 元画像の幅と高さ
          var originalWidth = img.width;
          var originalHeight = img.height;

          // 長辺が1920pxを超える場合、1920pxにリサイズ
          var maxHeight = 1920;
          var maxWidth = 1920;
          if (originalWidth > originalHeight) {
            canvas.width = Math.min(originalWidth, maxWidth);
            canvas.height = (canvas.width / originalWidth) * originalHeight;
          } else {
            canvas.height = Math.min(originalHeight, maxHeight);
            canvas.width = (canvas.height / originalHeight) * originalWidth;
          }

          // canvasに画像を描画
          ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

          // canvasからデータURLを取得し、Blobに変換してエディタに読み込む
          canvas.toBlob((blob) => {
            this.$refs.tuiImageEditor
              .invoke('loadImageFromFile', new File([blob], file.name))
              .then((result) => {
                console.log('画像がリサイズされて読み込まれました', result);

                this.$refs.tuiImageEditor.invoke('resize', {
                  width: canvas.width,
                  height: canvas.height,
                });
              })
              .catch((error) => {
                console.error('画像読み込みエラー:', error);
              });
          }, file.type);
        };

        // ファイルを読み込む
        reader.readAsDataURL(file);

        this.$refs.tuiImageEditor.invoke('clearUndoStack');
      },

      downloadImage: function () {
        var supportingFileAPI = !!(
          window.File &&
          window.FileList &&
          window.FileReader
        );
        // var imageName = this.imageEditor.getImageName()
        var imageName = this.$refs.tuiImageEditor.invoke('getImageName');
        // var dataURL = this.imageEditor.toDataURL()
        var dataURL = this.$refs.tuiImageEditor.invoke('toDataURL');
        var blob, type, w;

        if (supportingFileAPI) {
          blob = this.base64ToBlob(dataURL);
          type = blob.type.split('/')[1];
          if (imageName.split('.').pop() !== type) {
            imageName += '.' + type;
          }

          // Library: FileSaver - saveAs
          saveAs(blob, imageName); // eslint-disable-line
        } else {
          alert('This browser needs a file-server');
          w = window.open();
          w.document.body.innerHTML = '<img src="' + dataURL + '">';
        }
      },
    },
  };
</script>

<style scoped>
  * {
    font-family: 'M PLUS Rounded 1c', sans-serif;
  }
  .custom-chip {
    max-width: 80px; /* 横幅を小さく */
    padding: 0 8px; /* 内側の余白を調整 */
    font-size: 15px; /* フォントサイズを調整 */
    background-color: #f5f5f5; /* 通常の背景色 */
    color: #000; /* 通常の文字色 */
  }
  .primary-chip {
    max-width: 80px; /* 横幅を小さく */
    padding: 0 8px; /* 内側の余白を調整 */
    font-size: 15px; /* フォントサイズを調整 */
    background-color: #e91e63 !important; /* Vuetifyのprimary色 */
    color: white !important; /* primary色のときの文字色 */
  }

  .tui-image-editor-container .tui-image-editor-menu > .tui-image-editor-item,
  .tui-image-editor-container
    .tui-image-editor-help-menu
    > .tui-image-editor-item {
    position: relative;
    display: inline-block;
    border-radius: 2px;
    padding: 7px 2px 3px 2px !important;
    cursor: pointer;
    margin: 0 0px !important;
  }
</style>
